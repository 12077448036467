<template>
  <v-container fluid>
    <v-text-field label="Search" v-model="search"></v-text-field>
    <v-data-table
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="salesEmployee.Calls"
    >
      <!-- Status template -->
      <template v-slot:item.Status="props">
        <span v-if="props.item.Status == 'D'">Due</span>
        <span v-if="props.item.Status == 'C'">Closed</span>
        <span v-if="props.item.Status == 'A'">Abandoned</span>
      </template>
      <!-- end -->

      <!-- Approval  Status template -->
      <template v-slot:item.AprovalStatus="props">
        <span v-if="props.item.AprovalStatus == 'P'">Pending</span>
        <span v-if="props.item.AprovalStatus == 'A'">Approved</span>
      </template>
      <!-- end -->

      <!-- outlet template -->
      <template v-slot:item.outlet="props">
        <v-btn
          color="flatButton"
          text
          :to="`/outlet/${props.item.outlet.id}`"
        >{{props.item.outlet.CardName}}</v-btn>
      </template>
      <!-- end -->

      <!-- sales employee template -->
      <!-- <template v-slot:item.employees="props">
        <v-btn
          color="blue lighten-1"
          text
          small
          :to="`/sales/employee/${props.item.employees.id}`"
        >{{props.item.employees.SlpName}}</v-btn>
      </template> -->
      <!-- end -->

      <!-- Opened time template -->
      <template v-slot:item.OpenedTime="props">
        <span v-if="props.item.OpenedTime == null">Yet</span>
        <span v-else>{{props.item.OpenedTime}}</span>
      </template>
      <!-- end -->

      <!-- Close time template -->
      <template v-slot:item.CloseTime="props">
        <span v-if="props.item.CloseTime == null">Yet</span>
        <span v-else>{{props.item.CloseTime}}</span>
      </template>
      <!-- end -->

      <!-- Close time template -->
      <template v-slot:item.Repeat="props">
        <span v-if="props.item.Repeat == 'N'">Never</span>
        <span v-if="props.item.Repeat == 'D'">Daily</span>
        <span v-if="props.item.Repeat == 'W'">Weekly</span>
        <span v-if="props.item.Repeat == 'F'">Fortnightly</span>
        <span v-if="props.item.Repeat == 'M'">Monthly</span>
        <span v-if="props.item.Repeat == 'A'">Annually</span>
      </template>
      <!-- end -->
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      salesEmployee: {},
      id: null,
      search: null,
      headers: [
        { text: "Outlet", value: "outlet" },
        { text: "Date", value: "CallDate" },
        { text: "Start Time", value: "CallTime" },
        { text: "End Time", value: "CallEndTime" },
        { text: "Status", value: "Status" },
        { text: "Opened At", value: "OpenedTime" },
        { text: "Closed At", value: "CloseTime" },
        { text: "Repeat", value: "Repeat" },
        { text: "Approval Status", value: "AprovalStatus" },
      ],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true
    }
  },
  methods: {
    getData(val) {
      const self = this;
      self.id = val;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee/${val}`)
        .then((res) => {
          self.salesEmployee = res;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
</style>